export const TIPS_LIST = [
    {
        title: "Click any earlier answer to go back to the search",
        link: "",
        command: "",
        color: "",
        type: "tip",
    },
    {
        title: "Press up and down keys for earlier search text",
        link: "",
        command: "",
        color: "",
        type: "tip",
    },
    {
        title: "Report a problem by typing '/bug'",
        link: "",
        command: "/bug",
        color: "",
        type: "tip",
    },
    {
        title: "Give me feedback or suggestions by typing '/feedback'",
        link: "",
        command: "/feedback",
        color: "",
        type: "tip",
    },
    {
        title: "Go to websites directly. Try 'go facebook'",
        link: "",
        command: "go facebook",
        color: "",
        type: "tip",
    },
    {
        title: "Get answers. Try 'how long would it take to walk to mars'",
        link: "",
        command: "how long would it take to walk to mars",
        color: "",
        type: "tip",
    },
    {
        title: "Try 'go facebook'. Allow popups for me to open it.",
        link: "",
        command: "go facebook",
        color: "",
        type: "tip",
    },
    {
        title: "Try 'go gmail'. Allow popups for me to open it.",
        link: "",
        command: "go gmail",
        color: "",
        type: "tip",
    },
    {
        title: "Try 'go reddit'. Allow popups for me to open it.",
        link: "",
        command: "go reddit",
        color: "",
        type: "tip",
    },
    {
        title: "Try 'go amazon'. Allow popups for me to open it.",
        link: "",
        command: "go amazon",
        color: "",
        type: "tip",
    },
    {
        title: "Try 'go instagram'. Allow popups for me to open it.",
        link: "",
        command: "go instagram",
        color: "",
        type: "tip",
    },
    {
        title: "Try 'go spotify'. Allow popups for me to open it.",
        link: "",
        command: "go spotify",
        color: "",
        type: "tip",
    },
    {
        title: "Try 'go twitter'. Allow popups for me to open it.",
        link: "",
        command: "go twitter",
        color: "",
        type: "tip",
    },
    {
        title: "Try 'Go to The BBC'. Allow popups for me to open it.",
        link: "",
        command: "go to the bbc",
        color: "",
        type: "tip",
    },
    {
        title: "Get facts. Try 'what is the speed of light'",
        link: "",
        command: "what is the speed of light",
        color: "",
        type: "tip",
    },
    {
        title: "Play music. Try 'Play peaceful guitar music'",
        link: "",
        command: "Play peaceful guitar music",
        color: "",
        type: "tip",
    },
    {
        title: "Go direct to website content. Try 'go youtube cute pandas'",
        link: "",
        command: "go youtube cute pandas",
        color: "",
        type: "tip",
    },
    {
        title: "Search on your google drive. Try 'go google drive plans'",
        link: "",
        command: "go google drive plans",
        color: "",
        type: "tip",
    },
    {
        title: "Search on your dropbox. Try 'go dropbox budget'",
        link: "",
        command: "go dropbox budget",
        color: "",
        type: "tip",
    },
    {
        title: "Go straight repos. Try 'go github kubernetes-the-hard-way'",
        link: "",
        command: "go github kubernetes-the-hard-way",
        color: "",
        type: "tip",
    },
    {
        title: "Go to stackoverflow pages. Try 'go stackoverflow python tuples'",
        link: "",
        command: "go stackoverflow python tuples",
        color: "",
        type: "tip",
    },
    {
        title: "Search your gmail. Try 'go gmail newsletters'",
        link: "",
        command: "go gmail newsletters",
        color: "",
        type: "tip",
    },
    {
        title: "Go straight to dashboards. Try 'go heroku dashboard'",
        link: "",
        command: "go heroku dashboard",
        color: "",
        type: "tip",
    },

    {
        title: "Go straight to dashboards. Try 'go azure'",
        link: "",
        command: "go azure",
        color: "",
        type: "tip",
    },
    {
        title: "Go straight to the aws console. Try 'go aws console'",
        link: "",
        command: "go aws console",
        color: "",
        type: "tip",
    },
    {
        title: "Search on Amazon. Try 'go amazon shoes'",
        link: "",
        command: "go amazon shoes",
        color: "",
        type: "tip",
    },
    {
        title: "Go straight to your bank. Try 'go chase'",
        link: "",
        command: "go chase",
        color: "",
        type: "tip",
    },
    {
        title: "Go to Hacker News. Try 'go hn'",
        link: "",
        command: "go hn",
        color: "",
        type: "tip",
    },
    {
        title: "Go to the Go Language website. Try 'go golang'",
        link: "",
        command: "go golang",
        color: "",
        type: "tip",
    },
    {
        title: "Go to the Go game website. Try 'go usgo'",
        link: "",
        command: "go usgo",
        color: "",
        type: "tip",
    },
    {
        title: "Play Spotify. Try 'play the beatles on spotify'",
        link: "",
        command: "play the beatles on spotify",
        color: "",
        type: "tip",
    },
    {
        title: "Use normal language. Try 'what is the best city for a digital nomad?'",
        link: "",
        command: "what is the best city for a digital nomad",
        color: "",
        type: "tip",
    },
    {
        title: "Search music on Spotify. Try 'go spotify the beatles'",
        link: "",
        command: "go spotify the beatles",
        color: "",
        type: "tip",
    },
    {
        title: "Search Hacker News. Try 'go hn startups'",
        link: "",
        command: "go hn startups",
        color: "",
        type: "tip",
    },
    {
        title: "See what Elon's tweeting. Try 'go twitter elonmusk'",
        link: "",
        command: "go twitter elonmusk",
        color: "",
        type: "tip",
    },
    {
        title: `Find out the time anywhere. Try "What's the time in Bali?"`,
        link: "",
        command: "time in bali",
        color: "",
        type: "tip",
    },
    {
        title: `I can solve math problems. Try "sqrt(576)"`,
        link: "",
        command: "sqrt(576)",
        color: "",
        type: "tip",
    },
    {
        title: `I can search the web for you. Try "coronavirus trends"`,
        link: "",
        command: "coronavirus trends",
        color: "",
        type: "tip",
    },
    {
        title: `I can navigate to websites. Try "go instagram"`,
        link: "",
        command: "go instagram",
        color: "",
        type: "tip",
    },
    {
        title: `Go to searches on other sites. Try "go reddit bitcoin"`,
        link: "",
        command: "go reddit bitcoin",
        color: "",
        type: "tip",
    },
    {
        title: `Use '~e' to skip my AI: ~e site:cnn.com +"mars rover"`,
        link: "",
        command: `~e site:cnn.com +"mars rover"`,
        color: "",
        type: "tip",
    },
    {
        title: `Ask questions. Try "when did Biden first become a Senator?"`,
        link: "",
        command: `when did Biden first become a Senator?`,
        color: "",
        type: "tip",
    },
    {
        title: `Go to laptops on Best Buy. Try "go best buy laptops"`,
        link: "",
        command: `go best buy laptops`,
        color: "",
        type: "tip",
    },
    {
        title: `Use DDG-style !bang commands. Try "!a shoes"`,
        link: "",
        command: `!a shoes`,
        color: "",
        type: "tip",
    },

    {
        title: `Convert between things. Try "One cup in ounces"`,
        link: "",
        command: `One cup in ounces`,
        color: "",
        type: "tip",
    },
    {
        title: `[space] then up or down arrow to navigate through search history`,
        link: "",
        command: ``,
        color: "",
        type: "tip",
    },
    {
        title: "write a meal plan for the perfect dinner party with a moroccan theme",
        link: "",
        command:
            "write a meal plan for the perfect dinner party with a moroccan theme",
        color: "",
        type: "tip",
    },
];

export const SUGGESTED_COMMANDS_LIST = [
    {
        title: "Join Andi's Discord",
        link: "",
        command: "Join Andi's Discord",
        color: "",
        type: "tip",
    },
    {
        title: "Getting started",
        link: "",
        command: "Getting started",
        color: "",
        type: "tip",
    },
    {
        title: "Tell me all about you",
        link: "",
        command: "Getting started",
        color: "",
        type: "tip",
    },
    {
        title: "Do math, eg '37 * 25'",
        link: "",
        command: "37 * 25",
        color: "",
        type: "tip",
    },
    {
        title: "how to bake cookies",
        link: "",
        command: "how to bake cookies",
        color: "",
        type: "tip",
    },
    {
        title: "search reddit for mars rover",
        link: "",
        command: "search reddit for mars rover",
        color: "",
        type: "tip",
    },
    {
        title: "search stackoverflow for python list comprehension",
        link: "",
        command: "search stackoverflow for python list comprehension",
        color: "",
        type: "tip",
    },
    {
        title: "Convert units, eg '5lbs in kg'",
        link: "",
        command: "5lbs in kg",
        color: "",
        type: "tip",
    },
    {
        title: "Calculate, eg 'sqrt(576)'",
        link: "",
        command: "sqrt(576)",
        color: "",
        type: "tip",
    },
    {
        title: "Do calculus, eg 'd/dx x^2 y^4, d/dy x^2 y^4'",
        link: "",
        command: "d/dx x^2 y^4, d/dy x^2 y^4",
        color: "",
        type: "tip",
    },
    {
        title: "Navigate directly, eg 'go facebook'",
        link: "",
        command: "go facebook",
        color: "",
        type: "tip",
    },
    {
        title: "Go to websites, eg 'go new york times'",
        link: "",
        command: "go new york times",
        color: "",
        type: "tip",
    },
    {
        title: "Go to site searches, eg 'go youtube cute pandas'",
        link: "",
        command: "go youtube cute pandas",
        color: "",
        type: "tip",
    },
    {
        title: "Report a bug",
        link: "",
        command: "report a bug",
        color: "",
        type: "tip",
    },
    {
        title: "Give feedback",
        link: "",
        command: "Give feedback",
        color: "",
        type: "tip",
    },
    {
        title: "Go direct to apps, eg 'go gmail'",
        link: "",
        command: "go gmail",
        color: "",
        type: "tip",
    },
    {
        title: "Go direct to searches on apps, eg 'go reddit chatgpt alternative'",
        link: "",
        command: "go reddit chatgpt alternative",
        color: "",
        type: "tip",
    },
    {
        title: "Go direct to websites, eg 'go amazon'",
        link: "",
        command: "go amazon",
        color: "",
        type: "tip",
    },
    {
        title: "Go to an app search, eg 'go instagram cardib'",
        link: "",
        command: "go instagram cardib",
        color: "",
        type: "tip",
    },
    {
        title: "Open searches on Spotify, eg 'go spotify the beatles'",
        link: "",
        command: "go spotify the beatles",
        color: "",
        type: "tip",
    },
    {
        title: "Go to twitter searches, eg 'go twitter elonmusk'",
        link: "",
        command: "go twitter elonmusk",
        color: "",
        type: "tip",
    },
    {
        title: "Go direct to websites, eg 'go to the bbc'",
        link: "",
        command: "go to the bbc",
        color: "",
        type: "tip",
    },

    {
        title: "Play peaceful guitar music",
        link: "",
        command: "Play peaceful guitar music",
        color: "",
        type: "tip",
    },
    {
        title: "Search on youtube, eg 'go youtube cute pandas'",
        link: "",
        command: "go youtube cute pandas",
        color: "",
        type: "tip",
    },
    {
        title: "Find docs, eg 'go google drive to do list'",
        link: "",
        command: "go google drive to do list",
        color: "",
        type: "tip",
    },
    {
        title: "Find files, eg 'go dropbox budget'",
        link: "",
        command: "go dropbox budget",
        color: "",
        type: "tip",
    },
    {
        title: "Go to code, eg 'go github kubernetes-the-hard-way'",
        link: "",
        command: "go github kubernetes-the-hard-way",
        color: "",
        type: "tip",
    },
    {
        title: "search github for langchain",
        link: "",
        command: "search github for langchain",
        color: "",
        type: "tip",
    },

    {
        title: "search stackoverflow for python tuples",
        link: "",
        command: "search stackoverflow for python tuples",
        color: "",
        type: "tip",
    },
    {
        title: "Search on gmail, eg 'go gmail newsletters'",
        link: "",
        command: "go gmail newsletters",
        color: "",
        type: "tip",
    },
    {
        title: "Go to web tools, eg 'go azure'",
        link: "",
        command: "go azure",
        color: "",
        type: "tip",
    },
    {
        title: "Go to apps, eg 'go aws console'",
        link: "",
        command: "go aws console",
        color: "",
        type: "tip",
    },
    {
        title: "search amazon for nike sneakers",
        link: "",
        command: "search amazon for nike sneakers",
        color: "",
        type: "tip",
    },
    {
        title: "Go to Hacker News searches, eg 'go hn rust'",
        link: "",
        command: "go hn rust",
        color: "",
        type: "tip",
    },
    {
        title: "Go to websites, eg 'go golang'",
        link: "",
        command: "go golang",
        color: "",
        type: "tip",
    },
    {
        title: "play the beatles on spotify",
        link: "",
        command: "play the beatles on spotify",
        color: "",
        type: "tip",
    },
    {
        title: `Calculte, eg '5^2/sqrt(54)'`,
        link: "",
        command: "5^2/sqrt(54)",
        color: "",
        type: "tip",
    },
    {
        title: `Exact searches '/e site:cnn.com +"mars rover"'`,
        link: "",
        command: `/e site:cnn.com +"mars rover"`,
        color: "",
        type: "tip",
    },
    {
        title: `Exact searches '/e site:reddit.com +"chatgpt alternative"'`,
        link: "",
        command: `/e site:cnn.com +"mars rover"`,
        color: "",
        type: "tip",
    },
    {
        title: `Use Bang shortcuts, eg '!a shoes'`,
        link: "",
        command: `!a shoes`,
        color: "",
        type: "tip",
    },
    {
        title: `Use Bang shortcuts, eg '!a shoes'`,
        link: "",
        command: `!a shoes`,
        color: "",
        type: "tip",
    },
    {
        title: `Use Bang shortcuts, eg '!w nuclear fusion'`,
        link: "",
        command: `!w nuclear fusion`,
        color: "",
        type: "tip",
    },
    {
        title: `Use Bang shortcuts, eg '!spy the beatles'`,
        link: "",
        command: `!spy the beatles`,
        color: "",
        type: "tip",
    },
    {
        title: `Use Bang shortcuts, eg '!gm cafes'`,
        link: "",
        command: `!gm cafes`,
        color: "",
        type: "tip",
    },
    {
        title: `Convert, eg 'One cup in ounces'`,
        link: "",
        command: `One cup in ounces`,
        color: "",
        type: "tip",
    },
    {
        title: `Weather`,
        link: "",
        command: `Weather`,
        color: "",
        type: "tip",
    },
    {
        title: `Weather in London tomorrow`,
        link: "",
        command: `Weather in London tomorrow`,
        color: "",
        type: "tip",
    },
    {
        title: `Weather in San Francisco`,
        link: "",
        command: `Weather in San Francisco`,
        color: "",
        type: "tip",
    },
    {
        title: `Go direct: 'go tiktok'`,
        link: "",
        command: `go tiktok`,
        color: "",
        type: "tip",
    },
    {
        title: `Go direct: 'go tiktok real estate'`,
        link: "",
        command: `go tiktok real estate`,
        color: "",
        type: "tip",
    },
    {
        title: `Go direct: 'go discord'`,
        link: "",
        command: `go discord`,
        color: "",
        type: "tip",
    },
    {
        title: `Go direct: 'go canva'`,
        link: "",
        command: `go canva`,
        color: "",
        type: "tip",
    },
    {
        title: `Go direct: 'go canva templates'`,
        link: "",
        command: `go canva templates`,
        color: "",
        type: "tip",
    },
    {
        title: `Go direct: 'go youtube tv'`,
        link: "",
        command: `go youtube tv`,
        color: "",
        type: "tip",
    },
    {
        title: `Go direct: 'go youtube tv the simpsons'`,
        link: "",
        command: `go youtube tv the simpsons`,
        color: "",
        type: "tip",
    },
    {
        title: `Go direct: 'go whatsapp'`,
        link: "",
        command: `go whatsapp`,
        color: "",
        type: "tip",
    },
    {
        title: `Go direct: 'go yandex'`,
        link: "",
        command: `go yandex`,
        color: "",
        type: "tip",
    },
    {
        title: `Go direct: 'go flipkart'`,
        link: "",
        command: `go flipkart`,
        color: "",
        type: "tip",
    },
    {
        title: `Go direct: 'go flipkart'`,
        link: "",
        command: `go flipkart`,
        color: "",
        type: "tip",
    },
    {
        title: `Go direct: 'go twitch'`,
        link: "",
        command: `go twitch`,
        color: "",
        type: "tip",
    },
    {
        title: `Go direct: 'go twitch live coding'`,
        link: "",
        command: `go twitch live coding`,
        color: "",
        type: "tip",
    },
    {
        title: `Go direct: 'go cricbuzz'`,
        link: "",
        command: `go cricbuzz`,
        color: "",
        type: "tip",
    },
    {
        title: `Go direct: 'go hotmail'`,
        link: "",
        command: `go hotmail`,
        color: "",
        type: "tip",
    },
    {
        title: `Go direct: 'go wordle'`,
        link: "",
        command: `go wordle`,
        color: "",
        type: "tip",
    },
    {
        title: `Go direct: 'go google maps'`,
        link: "",
        command: `go google maps`,
        color: "",
        type: "tip",
    },
    {
        title: `Go direct: 'go chatgpt'`,
        link: "",
        command: `go chatgpt`,
        color: "",
        type: "tip",
    },
    {
        title: `Go direct: 'go live score'`,
        link: "",
        command: `go live score`,
        color: "",
        type: "tip",
    },
    {
        title: `Do algebra, eg 'factor 2x^5 - 19x^4 + 58x^3 - 67x^2 + 56x - 48'`,
        link: "",
        command: `factor 2x^5 - 19x^4 + 58x^3 - 67x^2 + 56x - 48`,
        color: "",
        type: "tip",
    },
    {
        title: `Do statistics, eg 'mean {21.3, 38.4, 12.7, 41.6}?'`,
        link: "",
        command: `mean {21.3, 38.4, 12.7, 41.6}?`,
        color: "",
        type: "tip",
    },
];

export const SUGGESTED_QUESTIONS_LIST = [
    {
        title: "What is generative AI?",
        link: "",
        command: "What is generative AI?",
        color: "",
        type: "tip",
    },
    {
        title: "What is the gdp per capita of china vs new zealand?",
        link: "",
        command: "What is the gdp per capita of china vs new zealand?",
        color: "",
        type: "tip",
    },
    {
        title: "how much of the world's electricity now comes from wind and solar?",
        link: "",
        command:
            "how much of the world's electricity now comes from wind and solar?",
        color: "",
        type: "tip",
    },
    {
        title: "What's the time in Bali?",
        link: "",
        command: "What's the time in Bali?",
        color: "",
        type: "tip",
    },
    {
        title: "Who is the Prime Minister of New Zealand?",
        link: "",
        command: "Who is the Prime Minister of New Zealand?",
        color: "",
        type: "tip",
    },
    {
        title: "When was the Magna Carta signed?",
        link: "",
        command: "When was the Magna Carta signed?",
        color: "",
        type: "tip",
    },
    {
        title: "How long would it take to walk to the moon?",
        link: "",
        command: "How long would it take to walk to the moon?",
        color: "",
        type: "tip",
    },
    {
        title: "How long has the James Webb Space Telescope been in space?",
        link: "",
        command: "How long has the James Webb Space Telescope been in space?",
        color: "",
        type: "tip",
    },
    {
        title: "What is the best city for a digital nomad?",
        link: "",
        command: "What is the best city for a digital nomad?",
        color: "",
        type: "tip",
    },
    {
        title: `What's the time in Sydney Australia?`,
        link: "",
        command: "What's the time in Sydney Australia",
        color: "",
        type: "tip",
    },
    {
        title: `What is the weather in Miami Beach?`,
        link: "",
        command: "What is the weather in Miami Beach?",
        color: "",
        type: "tip",
    },
    {
        title: `Where is Bora Bora?`,
        link: "",
        command: "Where is Bora Bora?",
        color: "",
        type: "tip",
    },
    {
        title: `What is the Mandalorian?`,
        link: "",
        command: "What is the Mandalorian?",
        color: "",
        type: "tip",
    },
    {
        title: `How old is George Lucas?`,
        link: "",
        command: "How old is George Lucas?",
        color: "",
        type: "tip",
    },
    {
        title: `When was Toy Story released?`,
        link: "",
        command: "When was Toy Story released?",
        color: "",
        type: "tip",
    },
    {
        title: `Who is Joan of Arc?`,
        link: "",
        command: "Who is Joan of Arc?",
        color: "",
        type: "tip",
    },
    {
        title: `What is nuclear fusion?`,
        link: "",
        command: "What is nuclear fusion?",
        color: "",
        type: "tip",
    },
    {
        title: `hello world in python`,
        link: "",
        command: "hello world in python",
        color: "",
        type: "tip",
    },
    {
        title: `Where is Mt Fuji?`,
        link: "",
        command: "Where is Mt Fuji?",
        color: "",
        type: "tip",
    },
    {
        title: `How tall is Mt Everest?`,
        link: "",
        command: "How tall is Mt Everest?",
        color: "",
        type: "tip",
    },
    {
        title: `What is the most beautiful beach in Costa Rica?`,
        link: "",
        command: "What is the most beautiful beach in Costa Rica?",
        color: "",
        type: "tip",
    },
    {
        title: `What is the most beautiful beach in The Whitsundays?`,
        link: "",
        command: "What is the most beautiful beach in The Whitsundays?",
        color: "",
        type: "tip",
    },
    {
        title: `What is the population of New Zealand?`,
        link: "",
        command: "What is the population of New Zealand?",
        color: "",
        type: "tip",
    },
    {
        title: "What is the speed of light?",
        link: "",
        command: "What is the speed of light?",
        color: "",
        type: "tip",
    },
    {
        title: `When did Biden first become a Senator?`,
        link: "",
        command: `When did Biden first become a Senator?`,
        color: "",
        type: "tip",
    },
    {
        title: `Who founded Y Combinator?`,
        link: "",
        command: `Who founded Y Combinator?`,
        color: "",
        type: "tip",
    },
    {
        title: "how long would it take to walk to mars?",
        link: "",
        command: "how long would it take to walk to mars?",
        color: "",
        type: "tip",
    },
    {
        title: "Who is Baby Yoda?",
        link: "",
        command: "Who is Baby Yoda?",
        color: "",
        type: "tip",
    },
    {
        title: "What is my ip?",
        link: "",
        command: "What is my ip?",
        color: "",
        type: "tip",
    },
    {
        title: "What time is it?",
        link: "",
        command: "What time is it?",
        color: "",
        type: "tip",
    },
    {
        title: "How to register to vote in the USA?",
        link: "",
        command: "How to register to vote in the USA?",
        color: "",
        type: "tip",
    },
    {
        title: "How to tie a tie?",
        link: "",
        command: "How to tie a tie?",
        color: "",
        type: "tip",
    },
    {
        title: "How to lose weight?",
        link: "",
        command: "How to lose weight?",
        color: "",
        type: "tip",
    },
    {
        title: "When is mothers day?",
        link: "",
        command: "When is mothers day?",
        color: "",
        type: "tip",
    },
    {
        title: "how many ounces in a pound?",
        link: "",
        command: "how many ounces in a pound?",
        color: "",
        type: "tip",
    },
    {
        title: "how many ounces in a gallon?",
        link: "",
        command: "how many ounces in a gallon?",
        color: "",
        type: "tip",
    },
    {
        title: "how to download youtube videos",
        link: "",
        command: "how to download youtube videos",
        color: "",
        type: "tip",
    },
    {
        title: "how to screenshot on mac",
        link: "",
        command: "how to screenshot on mac",
        color: "",
        type: "tip",
    },
    {
        title: "how old is donald trump?",
        link: "",
        command: "how old is donald trump?",
        color: "",
        type: "tip",
    },
    {
        title: "how to lose weight fast",
        link: "",
        command: "how to lose weight fast",
        color: "",
        type: "tip",
    },
    {
        title: "how to make pancakes",
        link: "",
        command: "how to make pancakes",
        color: "",
        type: "tip",
    },
    {
        title: "how to make money",
        link: "",
        command: "how to make money",
        color: "",
        type: "tip",
    },
    {
        title: "how many grams in an ounce?",
        link: "",
        command: "how many grams in an ounce?",
        color: "",
        type: "tip",
    },
    {
        title: "how many feet in a mile?",
        link: "",
        command: "how many feet in a mile?",
        color: "",
        type: "tip",
    },
    {
        title: "what time is it in california?",
        link: "",
        command: "what time is it in california?",
        color: "",
        type: "tip",
    },
    {
        title: "what does smh mean?",
        link: "",
        command: "what does smh mean?",
        color: "",
        type: "tip",
    },
    {
        title: "how to lose belly fat",
        link: "",
        command: "how to lose belly fat",
        color: "",
        type: "tip",
    },
    {
        title: "what is the weather today?",
        link: "",
        command: "what is the weather today?",
        color: "",
        type: "tip",
    },
    {
        title: "how to boil eggs",
        link: "",
        command: "how to boil eggs",
        color: "",
        type: "tip",
    },
    {
        title: "how to delete instagram account",
        link: "",
        command: "how to delete instagram account",
        color: "",
        type: "tip",
    },
    {
        title: "how many days till christmas?",
        link: "",
        command: "how many days till christmas?",
        color: "",
        type: "tip",
    },
    {
        title: "what time is it in london?",
        link: "",
        command: "what time is it in london?",
        color: "",
        type: "tip",
    },
    {
        title: "how old am i?",
        link: "",
        command: "how old am i?",
        color: "",
        type: "tip",
    },
    {
        title: "how to get rid of bed bugs",
        link: "",
        command: "how to get rid of bed bugs",
        color: "",
        type: "tip",
    },
    {
        title: "how to draw a rose",
        link: "",
        command: "how to draw a rose",
        color: "",
        type: "tip",
    },
    {
        title: "how to make pizza",
        link: "",
        command: "how to make pizza",
        color: "",
        type: "tip",
    },
    {
        title: "how many grams in a pound?",
        link: "",
        command: "how many grams in a pound?",
        color: "",
        type: "tip",
    },
    {
        title: "how many ounces in a quart?",
        link: "",
        command: "how many ounces in a quart?",
        color: "",
        type: "tip",
    },
    {
        title: "how to use snapchat",
        link: "",
        command: "how to use snapchat",
        color: "",
        type: "tip",
    },
    {
        title: "how to make french toast",
        link: "",
        command: "how to make french toast",
        color: "",
        type: "tip",
    },
    {
        title: "how many tablespoons in a cup?",
        link: "",
        command: "how many tablespoons in a cup?",
        color: "",
        type: "tip",
    },
    {
        title: "how to write a check",
        link: "",
        command: "how to write a check",
        color: "",
        type: "tip",
    },
    {
        title: "how to tie a bowtie",
        link: "",
        command: "how to tie a bowtie",
        color: "",
        type: "tip",
    },
    {
        title: "when is daylight saving time?",
        link: "",
        command: "when is daylight saving time?",
        color: "",
        type: "tip",
    },
    {
        title: "what does hmu mean?",
        link: "",
        command: "what does hmu mean?",
        color: "",
        type: "tip",
    },
    {
        title: "how many ounces in a quart?",
        link: "",
        command: "how many ounces in a quart?",
        color: "",
        type: "tip",
    },
    {
        title: "how to make money online",
        link: "",
        command: "how to make money online",
        color: "",
        type: "tip",
    },
    {
        title: "when is memorial day?",
        link: "",
        command: "when is memorial day?",
        color: "",
        type: "tip",
    },
    {
        title: "when is labor day?",
        link: "",
        command: "when is labor day?",
        color: "",
        type: "tip",
    },
    {
        title: "what is the temperature?",
        link: "",
        command: "what is the temperature?",
        color: "",
        type: "tip",
    },
    {
        title: "what time is it in hawaii?",
        link: "",
        command: "what time is it in hawaii?",
        color: "",
        type: "tip",
    },
    {
        title: "how to solve a rubix cube",
        link: "",
        command: "how to solve a rubix cube",
        color: "",
        type: "tip",
    },
    {
        title: "how many countries in the world?",
        link: "",
        command: "how many countries in the world?",
        color: "",
        type: "tip",
    },
    {
        title: "how long to boil eggs?",
        link: "",
        command: "how long to boil eggs?",
        color: "",
        type: "tip",
    },
    {
        title: "why is the sky blue?",
        link: "",
        command: "why is the sky blue?",
        color: "",
        type: "tip",
    },
    {
        title: "how to write a job cover letter",
        link: "",
        command: "how to write a job cover letter",
        color: "",
        type: "tip",
    },
    {
        title: "how much house can i afford?",
        link: "",
        command: "how much house can i afford?",
        color: "",
        type: "tip",
    },
    {
        title: "how to start your own business",
        link: "",
        command: "how to start your own business",
        color: "",
        type: "tip",
    },
    {
        title: "how to save money",
        link: "",
        command: "how to save money",
        color: "",
        type: "tip",
    },
    {
        title: "how to draw anime",
        link: "",
        command: "how to draw anime",
        color: "",
        type: "tip",
    },
    {
        title: "how to make scrambled eggs",
        link: "",
        command: "how to make scrambled eggs",
        color: "",
        type: "tip",
    },
    {
        title: "how to change gmail password",
        link: "",
        command: "how to change gmail password",
        color: "",
        type: "tip",
    },
    {
        title: "how to start a blog",
        link: "",
        command: "how to start a blog",
        color: "",
        type: "tip",
    },
    {
        title: "how to backup iphone",
        link: "",
        command: "how to backup iphone",
        color: "",
        type: "tip",
    },
    {
        title: "how to create a website",
        link: "",
        command: "how to create a website",
        color: "",
        type: "tip",
    },
    {
        title: "what to do when your bored?",
        link: "",
        command: "what to do when your bored?",
        color: "",
        type: "tip",
    },
    {
        title: "how to be happy",
        link: "",
        command: "how to be happy",
        color: "",
        type: "tip",
    },
    {
        title: "how long does it take to get to mars?",
        link: "",
        command: "how long does it take to get to mars?",
        color: "",
        type: "tip",
    },
    {
        title: "how to make homemade slime",
        link: "",
        command: "how to make homemade slime",
        color: "",
        type: "tip",
    },
    {
        title: "where to stay in bali",
        link: "",
        command: "where to stay in bali",
        color: "",
        type: "tip",
    },
];

export const SUGGESTED_GENERATIONS_LIST = [
    {
        title: "homemade slime ingredients list and recipe",
        link: "",
        command: "homemade slime ingredients list and recipe",
        color: "",
        type: "tip",
    },
    {
        title: "list the prettiest cities in the world",
        link: "",
        command: "list the prettiest cities in the world",
        color: "",
        type: "tip",
    },
    {
        title: "design a japanese zen garden",
        link: "",
        command: "design a japanese zen garden",
        color: "",
        type: "tip",
    },
    {
        title: "Write a story about current events in the style of Dr Seuss",
        link: "",
        command: "Write a story about current events in the style of Dr Seuss",
        color: "",
        type: "tip",
    },
    {
        title: "write a report on why FTX collapsed",
        link: "",
        command: "write a report on why FTX collapsed",
        color: "",
        type: "tip",
    },
    {
        title: "outline a plan for getting fit and healthy in 30 days",
        link: "",
        command: "outline a plan for getting fit and healthy in 30 days",
        color: "",
        type: "tip",
    },
    {
        title: "write a python function to calculate the days between two dates",
        link: "",
        command:
            "write a python function to calculate the days between two dates",
        color: "",
        type: "tip",
    },
    {
        title: "write a song about crypto in the style of Taylor Swift",
        link: "",
        command: "write a song about crypto in the style of Taylor Swift",
        color: "",
        type: "tip",
    },
    {
        title: "creative ideas to market a hip urban cafe",
        link: "",
        command: "creative ideas to market a hip urban cafe",
        color: "",
        type: "tip",
    },
    {
        title: "give me creative ideas for marketing a hip hop inspired coffee shop",
        link: "",
        command:
            "give me creative ideas for marketing a hip hop inspired coffee shop",
        color: "",
        type: "tip",
    },
    {
        title: "show me how to write a chatbot using next.js and vercel",
        link: "",
        command: "show me how to write a chatbot using next.js and vercel",
        color: "",
        type: "tip",
    },
    {
        title: "list the 10 best australian cricketers ever",
        link: "",
        command: "list the 10 best australian cricketers ever",
        color: "",
        type: "tip",
    },
    {
        title: "list the 10 best nba players of all time",
        link: "",
        command: "list the 10 best nba players of all time",
        color: "",
        type: "tip",
    },
    {
        title: "write a review of the most famous restaurant meals in the world",
        link: "",
        command:
            "write a review of the most famous restaurant meals in the world",
        color: "",
        type: "tip",
    },
    {
        title: "write a review of the best michelin star chefs in the world",
        link: "",
        command: "write a review of the best michelin star chefs in the world",
        color: "",
        type: "tip",
    },
    {
        title: "give me a list of the most beautiful places to take sunrise photos",
        link: "",
        command:
            "give me a list of the most beautiful places to take sunrise photos",
        color: "",
        type: "tip",
    },
    {
        title: "Write a report explaining why FTX collapsed like I'm in high school",
        link: "",
        command:
            "Write a report explaining why FTX collapsed like I'm in high school",
        color: "",
        type: "tip",
    },
    {
        title: "write reviews of the 5 best laptops for programmers",
        link: "",
        command: "write reviews of the 5 best laptops for programmers",
        color: "",
        type: "tip",
    },
    {
        title: "suggest ideas for lowering blood pressure as a 35 year old",
        link: "",
        command: "suggest ideas for lowering blood pressure as a 35 year old",
        color: "",
        type: "tip",
    },
    {
        title: "give me ideas for getting rid of hiccups",
        link: "",
        command: "give me ideas for getting rid of hiccups",
        color: "",
        type: "tip",
    },
    {
        title: "brainstorm ideas for being a better romantic partner",
        link: "",
        command: "brainstorm ideas for being a better romantic partner",
        color: "",
        type: "tip",
    },
    {
        title: "give me a tutorial on resetting my iphone",
        link: "",
        command: "give me a tutorial on resetting my iphone",
        color: "",
        type: "tip",
    },
    {
        title: "write an explanation of how uber works for my grandma",
        link: "",
        command: "write an explanation of how uber works for my grandma",
        color: "",
        type: "tip",
    },
    {
        title: "Outline how to pick a lock. I got locked out of my house",
        link: "",
        command: "Outline how to pick a lock. I got locked out of my house",
        color: "",
        type: "tip",
    },
    {
        title: "give me help on how to fall asleep fast",
        link: "",
        command: "give me help on how to fall asleep fast",
        color: "",
        type: "tip",
    },
    {
        title: "write the ultimate guide to making paper airplanes",
        link: "",
        command: "write the ultimate guide to making paper airplanes",
        color: "",
        type: "tip",
    },
    {
        title: "ideas for things to do at home when I'm bored",
        link: "",
        command: "ideas for things to do at home when I'm bored",
        color: "",
        type: "tip",
    },
    {
        title: "give me a tutorial on how to make the perfect resume",
        link: "",
        command: "give me a tutorial on how to make the perfect resume",
        color: "",
        type: "tip",
    },
    {
        title: "outline how to get into a top university",
        link: "",
        command: "outline how to get into a top university",
        color: "",
        type: "tip",
    },
    {
        title: "outline how to create an app",
        link: "",
        command: "outline how to create an app",
        color: "",
        type: "tip",
    },
];

export const SUGGESTED_SEARCHES_LIST = [
    {
        title: "prettiest cities in the world",
        link: "",
        command: "prettiest cities in the world",
        color: "",
        type: "tip",
    },
    {
        title: "history of the birkin bag",
        link: "",
        command: "history of the birkin bag",
        color: "",
        type: "tip",
    },

    {
        title: "Elon Musk",
        link: "",
        command: "Elon Musk",
        color: "",
        type: "tip",
    },
    {
        title: "Top 5 travel destinations",
        link: "",
        command: "Top 5 travel destinations",
        color: "",
        type: "tip",
    },
    {
        title: "apartments for rent in san francisco",
        link: "",
        command: "apartments for rent in san francisco",
        color: "",
        type: "tip",
    },

    {
        title: "best places to live as a digital nomad",
        link: "",
        command: "best places to live as a digital nomad",
        color: "",
        type: "tip",
    },
    {
        title: "number of baseballs that would fit inside the moon",
        link: "",
        command: "number of baseballs that would fit inside the moon",
        color: "",
        type: "tip",
    },
    {
        title: "best beaches in the whitsundays",
        link: "",
        command: "best beaches in the whitsundays",
        color: "",
        type: "tip",
    },
    {
        title: "top female fashion models",
        link: "",
        command: "top female fashion models",
        color: "",
        type: "tip",
    },
    {
        title: "most beautiful natural wonders in the world",
        link: "",
        command: "most beautiful natural wonders in the world",
        color: "",
        type: "tip",
    },
    {
        title: "james webb space telescope discoveries",
        link: "",
        command: "james webb space telescope discoveries",
        color: "",
        type: "tip",
    },
    {
        title: "paris hilton at the whitehouse",
        link: "",
        command: "paris hilton at the whitehouse",
        color: "",
        type: "tip",
    },
    {
        title: "world's most beautiful cafes",
        link: "",
        command: "world's most beautiful cafes",
        color: "",
        type: "tip",
    },
    {
        title: "openai dashboard",
        link: "",
        command: "openai dashboard",
        color: "",
        type: "tip",
    },

    {
        title: `coronavirus trends`,
        link: "",
        command: "coronavirus trends",
        color: "",
        type: "tip",
    },
    {
        title: `chess for beginners`,
        link: "",
        command: "chess for beginners",
        color: "",
        type: "tip",
    },
    {
        title: `download youtube videos`,
        link: "",
        command: "download youtube videos",
        color: "",
        type: "tip",
    },
    {
        title: `cut up a mango`,
        link: "",
        command: "cut up a mango",
        color: "",
        type: "tip",
    },
    {
        title: `create a next.js app`,
        link: "",
        command: "create a next.js app",
        color: "",
        type: "tip",
    },
    {
        title: `increase height`,
        link: "",
        command: "increase height",
        color: "",
        type: "tip",
    },
    {
        title: `taylor swift`,
        link: "",
        command: "taylor swift",
        color: "",
        type: "tip",
    },
    {
        title: `taylor swift boyfriend`,
        link: "",
        command: "taylor swift boyfriend",
        color: "",
        type: "tip",
    },
    {
        title: `learn to flirt`,
        link: "",
        command: "learn to flirt",
        color: "",
        type: "tip",
    },
    {
        title: `practice self love`,
        link: "",
        command: "practice self love",
        color: "",
        type: "tip",
    },
    {
        title: `learn to create AI apps`,
        link: "",
        command: "learn to create AI apps",
        color: "",
        type: "tip",
    },
    {
        title: `become an AI programmer`,
        link: "",
        command: "become an AI programmer",
        color: "",
        type: "tip",
    },
    {
        title: `no-code AI tools`,
        link: "",
        command: "no-code AI tools",
        color: "",
        type: "tip",
    },
    {
        title: `cake pop recipes`,
        link: "",
        command: "cake pop recipes",
        color: "",
        type: "tip",
    },
    {
        title: `interpret my dreams`,
        link: "",
        command: "interpret my dreams",
        color: "",
        type: "tip",
    },
    {
        title: `finding happiness in life`,
        link: "",
        command: "finding happiness in life",
        color: "",
        type: "tip",
    },
    {
        title: `AI career ideas`,
        link: "",
        command: "AI career ideas",
        color: "",
        type: "tip",
    },
    {
        title: `health and fitness tips`,
        link: "",
        command: "health and fitness tips",
        color: "",
        type: "tip",
    },
    {
        title: `kim kardashian`,
        link: "",
        command: "kim kardashian",
        color: "",
        type: "tip",
    },
    {
        title: `iron man`,
        link: "",
        command: "iron man",
        color: "",
        type: "tip",
    },
    {
        title: `HBO Silicon Valley analysis`,
        link: "",
        command: "HBO Silicon Valley analysis",
        color: "",
        type: "tip",
    },
    {
        title: `steve jobs pixar story`,
        link: "",
        command: "steve jobs pixar story",
        color: "",
        type: "tip",
    },
    {
        title: `paris hilton wedding dress`,
        link: "",
        command: "paris hilton wedding dress",
        color: "",
        type: "tip",
    },
    {
        title: `affordable places for digital nomads`,
        link: "",
        command: "affordable places for digital nomads",
        color: "",
        type: "tip",
    },
    {
        title: `top 10 beautiful places in the world`,
        link: "",
        command: "top 10 beautiful places in the world",
        color: "",
        type: "tip",
    },
    {
        title: `fitness tips of the day`,
        link: "",
        command: "fitness tips of the day",
        color: "",
        type: "tip",
    },
    {
        title: `most beautiful cities in america`,
        link: "",
        command: "most beautiful cities in america",
        color: "",
        type: "tip",
    },
];
